import {fetchGet, fetchPost, fetchPostPic, fetchDelete, fetchGetMess} from '../index';
import { 
  SiccoStatusData,
  SiccoOverviewData,
  SiccoBasicInfo,
  SiccoBaseTable,
  DealerInfoData,
  SelectData,
  ResponseData 
} from '@/views/RetailValidation/Sicco/types';

export const getSiccoOverview = (brands: string[]): Promise<SiccoOverviewData> => {
  return fetchPost('/rvapi/basic/overview/sicco/v2', brands)
}
export const getSiccoTable = (params = {}): Promise<SiccoBaseTable> => {
    return fetchPost('/rvapi/basic/approval/sicco/mulquery', params)
}
export const getApprovalStatus = (): Promise<SiccoStatusData[]> => {
   return fetchGet('/rvapi/stand/approvalStatus/dicSicco')
}
export const getCheckStatus = (): Promise<SiccoStatusData[]> => {
  return fetchGet('/rvapi/stand/checkStatus/dicSicco')
}
export const getBasicInfo = (params = {}): Promise<SiccoBasicInfo> => {
  return fetchGet('/rvapi/basic/approval/sicco/retailInfo', params)
}
export const deleteFailReason = (params: number) => {
  return fetchDelete(`/rvapi/basic/approval/sicco/deleteFailReason?id=${params}`)
}
export const postApproval = (params = {}): Promise<ResponseData> => {
  return fetchPostPic('/rvapi/basic/approval/sicco/approval', params)
}
export const postSingleApproval = (params = {}): Promise<ResponseData> => {
  return fetchPostPic('/rvapi/basic/approval/sicco/single/reject', params)
}
export const postBatchReject = (params = {}) => {
  return fetchPost('/rvapi/basic/approval/sicco/batch/reject', params)
}
export const getDealerName = (params = {}): Promise<string[]> => {
  return fetchGet('/rvapi/basic/retail/searchDealerName', params)
}
export const getDealerInfo = (params = {}): Promise<DealerInfoData> => {
  return fetchGet('/rvapi/basic/apply/dealer/dealerInfo', params)
}
export const getSelectData = (): Promise<SelectData> => {
  return fetchGet('/rvapi/basic/data/mainData')
}
//根据bulist获取区域
export const getRegionsbyBuList = (params = {}): Promise<any> => {
  return fetchGetMess('/dapi/noAuth/dealer/findBuByIds', params)
}
export const batchDownload = (params = {}, config = {}): Promise<any> => {
  return fetchPost('/rvapi/basic/approval/approval/batchDownload', params, config);
};